@tailwind base;
@tailwind components;
@tailwind utilities;
.App {
  text-align: center;
}
/* styles.css */
/* styles.css */
.outlined-green {
  color: white; /* Text color */
  text-shadow: 
      -1px -1px 0 
      #1b7053,  
       1px -1px 0 
       #1b7053,
      -1px  1px 0 
      #1b7053,
       1px  1px 0 
       #1b7053; /* Outline color */
}
.outlined-pink {
  color: white; /* Text color */
  text-shadow: 
      -1px -1px 0 
      #7c3088,  
       1px -1px 0 
       #7c3088,
      -1px  1px 0 
      #7c3088,
       1px  1px 0 
       #7c3088; /* Outline color */
}
.outlined-cyan {
  color: #ff43ff; /* Text color */
  text-shadow: 
      -1px -1px 0 #188d9f,  
       1px -1px 0 #188d9f,
      -1px  1px 0 #188d9f,
       1px  1px 0 #188d9f; /* Outline color */
}
.h-90 {
  width: 60%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.custom-datepicker .react-datepicker-popper {
  z-index: 2;
}

@media (max-width: 768px) {
  .custom-datepicker .react-datepicker-popper {
      transform: translate3d(0px, -200px, 0px) !important; /* Adjust the value as needed */
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
